<template>
  <svg class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="white"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></g><rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" /></svg>
  <!--<svg class="icon" xmlns="http://www.w3.org/2000/svg" :width='opts.width' :height='opts.height' viewBox="0 0 20 12">
    <path d="M1.783 -2.93896e-06L9.892 8.109L18.001 -3.64787e-06L19.782 1.781L9.891 11.672L-4.32349e-07 1.781L1.783 -2.93896e-06Z" :fill="opts.color"/>
  </svg>-->
</template>

<script>
import icon from '@/mixins/icon'

export default {
  mixins: [icon()],
  data () {
    return {
      defaults: {
        color: 'black',
        width: 16,
        height: 16
      }
    }
  }
}
</script>
