<template lang="pug">
  Frame.GrandmaThrewARoseAtYou(:class="{ playing, paused: !playing && (started || finished) }")
    - var n=1;
    while n <= 3
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'GrandmaThrewARoseAtYou',
  mixins: [
    mixinScene({
      'image': i => ({
        from: 'transform: scale(2);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 20
        }
      }),
      'image-2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1',
        options: {
          easing: 'ease',
          duration: 6,
          delay: 12
        }
      }),
      'image-3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1',
        options: {
          easing: 'linear',
          duration: 8,
          delay: 15
        }
      }),
      'image-idle': i => ({
        from: 'opacity: 1;',
        to: 'opacity: 0;',
        options: {
          easing: 'linear',
          duration: 26.9792
        }
      })
    }, {
      selector: selector => `.GrandmaThrewARoseAtYou .${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .GrandmaThrewARoseAtYou{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      will-change: opacity;
      transition: all 1s ease;
      transform-origin: 0 0;

      @for $i from 1 through 3 {
        &-#{$i} {
          background-image: url("/messages/msg_24/img/rose-#{$i}.jpg");
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }

    @media (orientation: landscape) {
      .image{
        transform-origin: 50% 0;
        background-size: auto 100%;
      }
    }
  }
</style>
