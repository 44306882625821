<template lang="pug">
  Frame.Abba(:class="{ playing, paused: !playing && (started || finished) }")
    .image.image-zeki
    .image.image-frame(data-fadein)
    .image.image-poster-with-text(data-fadein)
    .image.image-zeki-name(data-fadein)
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'Abba',
  mixins: [
    mixinScene({
      'zeki': i => ({
        from: 'transform: scale(2.05);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 1
        }
      }),
      'frame': i => ({
        from: 'transform: scale(2.05);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease-out',
          duration: 1,
          delay: 1
        }
      }),
      'frame[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 1,
          delay: 1
        }
      }),
      'poster-with-text': i => ({
        from: 'clip-path: inset(0 100% 0 0);',
        to: 'clip-path: inset(0 0 0 0);',
        options: {
          easing: 'ease-in',
          duration: 1,
          delay: 2
        }
      }),
      'poster-with-text[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.5,
          delay: 2
        }
      }),
      'zeki-name': i => ({
        from: 'transform: scale(0.5);',
        to: 'transform: scale(1);',
        options: {
          easing: 'cubic-bezier(0.49, 0.57, 0.23, 1.45)',
          duration: 0.5,
          delay: 3
        }
      }),
      'zeki-name[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.5,
          delay: 3
        }
      }),
      'idle': i => ({
        from: 'opacity: 0',
        to: 'opacity: 1',
        options: {
          easing: 'linear',
          duration: 6.6032
        }
      })
    },
    {
      // duration: 6.588,
      selector: (selector) => `.Abba .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .Abba{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all 1s ease;
      transform-origin: 50% 63%;

      &-zeki{
        background-image: url('/messages/msg_31/img/gazino-zeki.jpg');
      }
      &-frame{
        background-image: url('/messages/msg_31/img/gazino-frame.png');
      }
      &-poster-with-text{
        background-image: url('/messages/msg_31/img/gazino-poster-with-text.jpg');
      }
      &-zeki-name{
        background-image: url('/messages/msg_31/img/gazino-zeki-name.png');
        background-size: 72%;
        background-position: center 87%;
      }
    }

    @media (orientation: landscape) {
      .image{
        background-size: contain;

        &:last-child{background-size: 23%;}
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
