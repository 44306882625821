import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales'
import queryString from './utils/queryString'
import getLocaleByDomain from './utils/getLocaleByDomain'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const list = {}

  locales.forEach(l => {
    list[l] = require(`@/locales/translations/${l}.json`)
  })
  return list
}

const deviceLang = window.navigator.language.split('-')[0]
const locale = window.localStorage.getItem('lang') || getLocaleByDomain() || queryString().lang || deviceLang || process.env.VUE_APP_I18N_LOCALE

export default new VueI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
})
