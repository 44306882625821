<template lang="pug">
  component.Message(:is="message", :images="images", v-bind="$attrs", v-on="$listeners")
</template>

<script>
import AreYouThereBrotherZeki from './AreYouThereBrotherZeki'
import GeographyTeacher from './GeographyTeacher'
import WhyArmy from './WhyArmy'
import NeverTurnedHisBackOnStage from './NeverTurnedHisBackOnStage'
import ZekiMurenAtKucukCiftlikPark from './ZekiMurenAtKucukCiftlikPark'
import DamnWithAllTheseGuys from './DamnWithAllTheseGuys'
import TaughtMeHowtoRestMySoul from './TaughtMeHowtoRestMySoul'
import Girlboy from './Girlboy'
import PoetSunOfArt from './PoetSunOfArt'
import IstanbulBombing from './IstanbulBombing'
import GrandmaThrewARoseAtYou from './GrandmaThrewARoseAtYou'
import TurkeyDeveloped from './TurkeyDeveloped'
import ZekiInMyDreams from './ZekiInMyDreams'
import Abba from './Abba'
import NearDeathExperience from './NearDeathExperience'
import CantFindLove from './CantFindLove'
import BursaBreaksHisPromise from './BursaBreaksHisPromise'
import WhyThoseOutfits from './WhyThoseOutfits'
import LongingForZekiMuren from './LongingForZekiMuren'
import ALongOverduePoem from './ALongOverduePoem'
import GreatestRevolution from './GreatestRevolution'
import FatherAndDaughter from './FatherAndDaughter'
import Highschoolers from './Highschoolers'
import Funeral from './Funeral'
import BestThing from './BestThing'
import SomeoneAsLonelyAsYou from './SomeoneAsLonelyAsYou'
import DeceasedMother from './DeceasedMother'
import Credits from './Credits'
import ZekiInMovie from './ZekiInMovie'
import WereYouInLove from './WereYouInLove'
import LGBTQIcon from './LGBTQIcon'
import RichestPerson from './RichestPerson'
import Pride from './Pride'

export default {
  name: 'Message',
  props: {
    message: { type: String, default: '' },
    images: { type: Array, default: () => ([]) }
  },
  components: {
    AreYouThereBrotherZeki,
    GeographyTeacher,
    WhyArmy,
    NeverTurnedHisBackOnStage,
    ZekiMurenAtKucukCiftlikPark,
    DamnWithAllTheseGuys,
    TaughtMeHowtoRestMySoul,
    Girlboy,
    PoetSunOfArt,
    IstanbulBombing,
    GrandmaThrewARoseAtYou,
    TurkeyDeveloped,
    ZekiInMyDreams,
    Abba,
    NearDeathExperience,
    CantFindLove,
    BursaBreaksHisPromise,
    WhyThoseOutfits,
    LongingForZekiMuren,
    ALongOverduePoem,
    GreatestRevolution,
    FatherAndDaughter,
    Highschoolers,
    Funeral,
    BestThing,
    SomeoneAsLonelyAsYou,
    DeceasedMother,
    Credits,
    ZekiInMovie,
    WereYouInLove,
    LGBTQIcon,
    RichestPerson,
    Pride
  }
}
</script>
