<template lang="pug">
  .SplashScreen
    transition(name="fadeIn", mode="out-in")
      .SplashScreen-info(v-if="showInfo")
        transition(name="fadeInSlideUp", mode="out-in")
          .SplashScreen-head(v-if="showHead")
            .SplashScreen-slogan {{ $t('will_zeki_muren_hear_us') }}
            transition(name="fadeIn")
              Pulse(v-if="showPulse")

        transition(name="fadeIn")
          .SplashScreen-footer(v-if="showHeadphone")
            .SplashScreen-headphone
              .SplashScreen-headphone-icon
              .SplashScreen-headphone-text {{ $root.$t('text.best_with_headphones') }}

    transition(name="fadeInSlow", mode="out-in")
      .SplashScreen-enterance(v-if="showEnterance")
        transition(name="fadeInBounce", mode="out-in")
          .enterBtn(
            v-if="showEnterButton",
            @click="$emit('enter')")
            span {{ $t('enter') }}
</template>

<script>
export default {
  name: 'SplashScreen',
  props: {
    enterance: { type: Boolean, default: false }
  },
  data () {
    return {
      showInfo: true,
      showEnterance: false,
      showHead: false,
      showPulse: false,
      showHeadphone: false,
      showEnterButton: false
    }
  },
  watch: {
    enterance (newVal) {
      if (newVal) {
        this.showInfo = false
        this.showEnterance = true

        setTimeout(() => {
          this.showEnterButton = true
        }, 3000)
      }
    }
  },
  mounted () {
    this.showHeadphone = true

    setTimeout(() => {
      setTimeout(() => {
        this.showHead = true
        this.showPulse = true
      }, 1000)
    }, 0)
  }
}
</script>

<style lang="scss">
  .SplashScreen{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    overflow: hidden;
    background-color: black;

    &-info, &-enterance{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-head{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-slogan{
      font-weight: 100;
      font-style: italic;
      font-size: 2.1rem;
      margin-bottom: 10px;
      text-align: center;
    }

    &-headphone{
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      left: 0;
      width: 100%;
      bottom: 30px;
      opacity: 0.5;

      &-icon{
        width: 48px;
        height: 48px;
        background: url('/img/headphone.png') no-repeat center;
        background-size: 100%;
      }

      &-text{
        /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        margin-top: 5px;
      }
    }

    &-enterance{
      justify-content: center;
      background: url('/img/intro.gif') no-repeat center;
      background-size: 100% auto;
      padding: 30px;

      .enterBtn{
        width: 120px;
        height: 120px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 100;
        color: $primary;
        padding-bottom: 10px;
        margin-top: 100%;

        span{
          position: relative;
          z-index: 1;
          line-height: normal;
          margin-top: 3px;
          transition: color 0.2s linear;
        }

        &:before, &:after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
          pointer-events: none;
          border: 1px solid $primary;
          border-radius: 50%;
          opacity: 0.5;
          transition: background-color 0.2s linear, box-shadow 0.2s linear, opacity 0.2s linear;
        }

        &:after{
          opacity: 0.8;
          left: 5px;
          right: 5px;
          top: 5px;
          bottom: 5px;
        }

        &:hover, &:focus{
          span{color: black;}

          &:before{
            opacity: 0.7;
            box-shadow: 0 0 40px $primary;
          }
          &:after{
            opacity: 1;
            background-color: $primary;
          }
        }
      }

      @media (orientation: landscape) {
        background-size: 100% auto;

        .enterBtn{margin-top: 100px;}
      }
    }

    @media (orientation: landscape) {
      &-slogan{
        margin-top: -60px;
      }
    }
  }
</style>
