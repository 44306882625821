<template lang="pug">
  Frame.BursaBreaksHisPromise(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 0;
    while n <= 7
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'BursaBreaksHisPromise',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 3.5
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.05,
          delay: 8.75
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.05,
          delay: 17.75
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.05,
          delay: 21.75
        }
      }),
      '5': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.05,
          delay: 31.25
        }
      }),
      '6': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.05,
          delay: 39.25
        }
      }),
      '7': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.05,
          delay: 51.25
        }
      }),
      // This is dummy control for timeline
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 63.8432
        }
      })
    }, {
      selector: selector => `.BursaBreaksHisPromise .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .BursaBreaksHisPromise{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @for $i from 0 through 7 {
        &-#{$i} {
          background-image: url("/messages/msg_17/img/17_Bursa_BreaksHisPromise_WideStill#{$i}0.jpg");
        }
      }
    }

    @media (orientation: landscape) {
      .image{
        background-position: center 70%;
      }
    }
  }
</style>
