<i18n>
{
  "en": {
    "title": "Welcome!",
    "desc": "<p>Please visit <b>Zeki Müren Hotline*</b> on your mobile phone.</p>",
    "what_is": "<span class='what-is'>*What is <a class=\"js-toggle\" href=\"javascript:;\">Zeki Müren Hotline</a>?</span>",
    "detail": "<p><b>Zeki Müren Hotline</b> started as a simple hotline in 2015, collecting everyday people's messages to and about Zeki Müren — Turkey's most beloved and equally controversial pop star. An homage to the intimacy Müren established with his fans, as well as a throwback to the 1990's hotline phenomenon, this participatory project quickly became a sensation in Turkey. During the few months it was active, the hotline received hundreds of messages, often expressing nostalgia for the deceased icon and Turkey's bygone days. The <em>Zeki Müren Hotline</em> web experience presents a selection from those messages.</p><p>Explore Zeki Müren's life, work and legacy, through the eyes of his fans.</p>"
  },
  "tr": {
    "title": "Hoşgeldiniz.",
    "desc": "<p>Lütfen <b>Zeki Müren Hattı*</b> web belgeselini cep telefonunuzdan ziyaret edin.</p>",
    "what_is": "<span class='what-is'>*<a class=\"js-toggle\" href=\"javascript:;\">Zeki Müren Hattı</a> nedir?</span>",
    "detail": "<p><b>Zeki Müren Hattı</b>, arayanların Türkiye’nin en sevilen ve sıradışı popstarı Zeki Müren'e mesajlar bıraktığı ve onunla ilgili duygularını ve anılarını paylaştıkları bir telefon hattı olarak 2015 yılında hayata geçti. Müren'in sevenleriyle kurduğu samimi ilişkiyi hatırlatan ve 1990’ların 900lü hat furyasına referans ile oluşan bu katılımcı proje, kısa sürede viral bir sansasyon oldu. Telefon hattının aktif olduğu birkaç aylık süre içerisinde, <em>Zeki Müren Hattı</em>'na, Müren'e ve onun zamanının Türkiye'sine duyulan nostaljiyle bezeli yüzlerce mesaj bırakıldı. <em>Zeki Müren Hattı</em> web deneyimi ise size bu mesajlardan bir seçki sunuyor.</p><p>Zeki Müren'in hayatını, eserlerini ve kültürel mirasını, sevenlerinin gözünden keşfedin.</p>"
  }
}
</i18n>

<template lang="pug">
  .DesktopMessage
    transition(name="fadeInFast", mode="out-in")
      .detail(v-if="showDetail")
        a.close(href="javascript:;", @click="closeDetail")
          Icon(name="Close", color="currentColor")
        .content
          .right(v-html="$t('detail')")
    .sideContent(:class="[locale]")
      h1 {{ $t('title') }}
      div(v-html="$t('desc')")
      p(v-html="$t('what_is')")
</template>

<script>
export default {
  name: 'DesktopMessage',
  data () {
    return {
      showDetail: false
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    openDetail () {
      this.showDetail = true
    },
    closeDetail () {
      this.showDetail = false
    }
  },
  mounted () {
    const toggle = this.$el.querySelector('.js-toggle')
    toggle.addEventListener('click', this.openDetail)
  }
}
</script>

<style lang="scss">
  @import "./assets/stylesheet/anim";

  .DesktopMessage{
    width: 100vw;
    height: 100vh;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-size: 1rem;
    font-weight: 100;
    background: url('/img/zeki-desktop-splash.jpg') center;
    background-size: cover;

    b{font-weight: 500;}
    p{margin-top: 0;}

    a{
      color: #FFF;
      font-weight: 500;
      text-decoration: none;
      display: inline-block;
      border-bottom: 1px solid currentColor;

      &:hover, &:focus{
        color: darken(#FFF, 10%);
      }
    }

    .sideContent{
      max-width: 555px;
      margin-left: 30%;

      h1{
        font-size: 4em;
        font-size: 4rem;
        font-weight: 100;
        margin-bottom: .5em;
        margin-bottom: .5rem;
        margin-top: 0;
        margin-left: -0.08em;
      }

      &.tr{
        max-width: 600px;
      }
    }

    .content{
      max-width: 500px;
      display: flex;
      padding: 20px;

      .left{padding-right: 20px;}

      img{max-width: 140px;}
    }

    .what-is{
      font-size: .875em;
      font-size: .875rem;
      font-weight: 100;
    }

    .detail{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;

      .close{
        position: absolute;
        right: 0;
        top: 0;
        padding: 20px;
        border-bottom: 0;

        .icon{
          width: 30px;
          height: 30px;
        }
      }

      .content{
        width: 70%;
        max-width: 710px;
      }
    }
  }
</style>
