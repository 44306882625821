<template lang="pug">
  .AudioPlayer
    audio(ref="Audio", :src="src")
      |Your browser does not support the #[code audio] element.

    // OVERLAY TOGGLE FOR CONTROLS
    //- .AudioPlayer-overlay(
    //-   v-if="status.started",
    //-   @click="togglePlayingButtons")

    .AudioPlayer-time(v-if="debug") {{ status.currentTime }} / {{ status.duration }}

    // MESSAGE CAPTIONS
    transition(name="fadeInFast")
      .AudioPlayer-captions(v-if="status.showCaptions")
        .AudioPlayer-captions-head
          button.AudioPlayer-captions-close(@click.stop.prevent="closeCaption")
            Icon(name="Close", color="currentColor")

        .AudioPlayer-captions-content(v-html="message")

    // SHARE SCREEN
    transition(name="fadeInFast")
      .AudioPlayer-captions(v-if="showShare")
        .AudioPlayer-captions-head
          button.AudioPlayer-captions-close(@click.stop.prevent="showShare = false")
            Icon(name="Close", color="currentColor")

        .AudioPlayer-captions-content
          .AudioPlayer-share
            h3 {{ $root.$t('text.share') }}
            .AudioPlayer-share-list
              a.AudioPlayer-share-btn(@click.prevent="shareWith('facebook')")
                Icon(name="Facebook", color="currentColor")
              a.AudioPlayer-share-btn(@click.prevent="shareWith('twitter')")
                Icon(name="Twitter", color="currentColor")
              //- a.AudioPlayer-share-btn(@click.prevent="shareWith('linkedin')")
              //-   Icon(name="Linkedin", color="currentColor")
              //- a.AudioPlayer-share-btn(@click.prevent="shareWith('reddit')")
              //-   Icon(name="Reddit", color="currentColor")

    // CONTROLS FOR PLAYING
    transition(name="fadeInFast")
      .AudioPlayer-controls-playing(
        v-if="status.playingControls",
        @click.stop.prevent="status.playingControls = false")
        .AudioPlayer-controls-playing-main
          button.AudioPlayer-controls-rewind(@click.stop.prevent="rewind(10)")
          button.AudioPlayer-controls-playpause(
            :class="{paused: !status.playing}",
            @click.stop.prevent="togglePlay")
          button.AudioPlayer-controls-forward(@click.stop.prevent="forward(10)")

        .AudioPlayer-controls-playing-secondary
          button.AudioPlayer-controls-share(@click="toggleShare")
          button.AudioPlayer-controls-captions(
            :class="{locked: !status.captionUnlocked}",
            @click.stop.prevent="openCaption")

    // CONTROLS FOR FIRST STATE
    transition(name="fadeInFast")
      .AudioPlayer-controls(v-if="!status.started")
        button.AudioPlayer-controls-share(@click="toggleShare")
        button.AudioPlayer-controls-replay(v-if="status.finished", @click.stop.prevent="replay")
        button.AudioPlayer-controls-start(v-else, @click.stop.prevent="play")
        button.AudioPlayer-controls-captions(
          :class="{locked: !status.captionUnlocked}",
          @click.stop.prevent="openCaption")

    // SUBTITLE
    .AudioPlayer-subtitle(v-if="subtitles.length > 0 && line", v-html="line")

    // PROGRESSBAR
    .AudioPlayer-progress(:class="{started: status.started}")
      .AudioPlayer-progress-bar(:style="`width: ${status.progress}%;`")
</template>

<script>

let defaultStatus = {
  started: false,
  finished: false,
  showCaptions: false,
  captionUnlocked: false,
  playing: false,
  playingControls: false,
  duration: 0,
  currentTime: 0,
  progress: 0
}

export default {
  name: 'AudioPlayer',
  props: {
    src: { type: String, default: '' },
    subtitle: { type: Array, default: () => ([]) },
    message: { type: String, default: '' },
    debug: { type: Boolean },
    value: {}
  },
  data () {
    return {
      audio: null,
      line: '',
      showShare: false,
      status: Object.assign({}, defaultStatus)
    }
  },
  computed: {
    subtitles () {
      return this.parseSubtitles()
    }
  },
  watch: {
    value: {
      deep: true,
      handler (newVal) {
        this.setState(Object.assign({}, newVal))
      }
    }
  },
  methods: {
    toggleShare () {
      this.showShare = !this.showShare
    },
    shareWith (target) {
      const title = encodeURIComponent(this.$root.$t('text.share_message.title'))
      const message = encodeURIComponent(this.$root.$t('text.share_message.text'))
      const url = encodeURIComponent(document.URL.slice(0, -1))
      const socialMedias = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`,
        twitter: `https://twitter.com/intent/tweet?text=${message}&url=${url}&hashtags=zekimürenhotline,zekimüren&via=zekimurenhatti`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
        reddit: `http://www.reddit.com/submit?url=${url}&title=${title}`
      }
      const share = socialMedias[target]
      if (!share) return
      window.open(share)
    },
    setState (value = this.value) {
      this.status = Object.assign({}, {
        ...defaultStatus,
        ...value
      })
    },
    play () {
      this.audio.play()
      this.status.started = true
      this.$emit('input', this.status)
    },
    pause () {
      this.audio.pause()
      this.$emit('input', this.status)
    },
    togglePlay () {
      if (this.status.playing) {
        this.pause()
      } else {
        this.play()
      }
      this.$emit('input', this.status)
    },
    replay () {
      // hide the swipe tip
      document.getElementById('swipe-continue-tip').style.opacity = 0
      this.line = ''
      this.status = {
        ...defaultStatus,
        captionUnlocked: this.status.captionUnlocked
      }
      this.$emit('input', this.status)
    },
    reset () {
      this.line = ''
      this.status = Object.assign({}, defaultStatus)
      this.$emit('input', this.status)
    },
    rewind (sec) {
      let currentTime = this.audio.currentTime - sec
      if (currentTime < 0) currentTime = 0
      this.audio.currentTime = currentTime
      this.status.currentTime = currentTime
      this.line = this.getCurrentSubtitle(currentTime)
      this.setProgress()
      this.$emit('input', this.status)
    },
    forward (sec) {
      let currentTime = this.audio.currentTime + sec
      if (currentTime > this.audio.duration) {
        currentTime = this.audio.duration
      }
      this.audio.currentTime = currentTime
      this.status.currentTime = currentTime
      this.line = this.getCurrentSubtitle(currentTime)
      this.setProgress()
      this.$emit('input', this.status)
    },
    setProgress () {
      let { currentTime, duration } = this.audio
      this.status.progress = (currentTime / duration) * 100
    },
    openCaption () {
      if (this.status.captionUnlocked) {
        this.status.showCaptions = true
        this.$emit('input', this.status)
      }
    },
    closeCaption () {
      this.status.showCaptions = false
      this.$emit('input', this.status)
      // show swipe tip
      if (this.status.playingControls === false) {
        document.getElementById('swipe-continue-tip').style.opacity = 1
      }
    },
    onTimeUpdate (e) {
      let { currentTime, duration } = this.audio
      if (!this.status.finished && this.status.playing) {
        this.status.currentTime = currentTime
        this.setProgress()
        this.status.duration = duration
        this.line = this.getCurrentSubtitle(currentTime)
        this.$emit('input', this.status)
      }
    },
    onPause () {
      this.status.playing = false
      this.$emit('input', this.status)
    },
    onPlay () {
      this.status.playing = true
      this.$emit('input', this.status)
    },
    onEnded () {
      this.status.started = false
      this.status.finished = true
      this.status.playing = false
      this.status.playingControls = false
      this.status.showCaptions = !!this.message
      this.status.captionUnlocked = !!this.message
      this.line = ''
      this.$emit('input', this.status)
    },
    togglePlayingButtons () {
      if (this.status.started) {
        this.status.playingControls = !this.status.playingControls
        this.$emit('input', this.status)
      }
    },
    parseSubtitles () {
      return this.subtitle.map(line => {
        let [range, text] = line.split('->').map(l => l.trim())
        let [start, end] = range.split('<>').map(l => {
          return parseFloat(l.trim())
        })
        return { start, end, text }
      })
    },
    getCurrentSubtitle (time) {
      let subtitle = this.subtitles.find(line => {
        let { start, end } = line
        return time >= start && time < end
      })
      return (subtitle || {}).text || ''
    },
    onBlurWindow () {
      if (this.status.started && !this.audio.paused) {
        this.pause()
      }
    }
  },
  beforeDestroy () {
    this.audio.removeEventListener('timeupdate', this.onTimeUpdate)
    this.audio.removeEventListener('play', this.onPlay)
    this.audio.removeEventListener('pause', this.onPause)
    this.audio.removeEventListener('ended', this.onEnded)
    window.removeEventListener('blur', this.onBlurWindow)
    document.removeEventListener('click', this.togglePlayingButtons, false)
  },
  mounted () {
    this.audio = this.$refs.Audio
    this.audio.addEventListener('timeupdate', this.onTimeUpdate)
    this.audio.addEventListener('play', this.onPlay)
    this.audio.addEventListener('pause', this.onPause)
    this.audio.addEventListener('ended', this.onEnded)
    window.addEventListener('blur', this.onBlurWindow)
    document.addEventListener('click', this.togglePlayingButtons, false)
    this.setState()
  }
}
</script>

<style lang="scss">
  .AudioPlayer{
    position: relative;
    z-index: 5;

    button{
      border: 0;
      padding: 0;
      outline: none;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }

    &-time{
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px 10px;
      background-color: rgba($primary, 0.8);
      font-size: 70%;
    }

    &-overlay,
    &-captions,
    &-controls,
    &-controls-playing,
    &-progress,
    &-subtitle{position: fixed;}

    &-overlay{
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &-captions{
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background-color: rgba($primary, 0.8);
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p{margin-top: 0;}

      &-head{padding-bottom: 15px;}

      &-close{
        border: 0;
        color: white;
        padding: 20px !important;

        .icon{
          width: 25px;
          height: 25px;
          display: block;
        }
      }

      &-content{
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.5rem;
        font-style: normal;
        flex: 1;
        overflow: auto;
        padding: 10px 18px 16px 16px;
      }
    }

    &-share{
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: $font-family;
      padding-bottom: 80px;

      h3 {
        font-size: 35px;
        margin-top: 0;
        font-weight: 500;
        margin-bottom: 15px;
      }

      &-list{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      &-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        color: white;
        border: 1px solid white;
        border-radius: 50%;
        padding: 15px;
        transition: color 0.2s linear, background-color 0.2s linear;

        .icon{
          width: 35px;
          height: 35px;
        }

        &:active, &:focus{
          background-color: white;
          color: $primary;
        }
      }
    }

    &-controls{
      left: 0;
      bottom: 23px;
      width: 100%;
      z-index: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 10px;

      &-share, &-captions, &-rewind, &-forward{
        width: 77px;
        height: 77px;
      }

      &-start{
        height: 120px;
        width: 120px;
        background-position: 0 0;
        background-size: 400%;

        &:hover, &:focus{
          background-position: -120px 0;
        }
      }

      &-replay{
        height: 120px;
        width: 120px;
        background-position: 0 -120px;
        background-size: 400%;

        &:hover, &:focus{
          background-position: -120px -120px;
        }
      }

      &-share{
        background-position: -231px -264px;
        background-size: 528px 341px;

        &:hover, &:focus{
          background-position: -(231 + 77px) -264px;
        }
      }

      &-captions{
        background-position: -77px -264px;
        background-size: 528px 341px;

        &:hover, &:focus{
          background-position: (-77px * 2) -264px;
        }

        &.locked {
          background-position: 0 -264px;
          border-radius: 0;
        }
      }

      &-rewind, &-forward{
        width: 70px;
        height: 70px;
        background-position: 0 0;
        background-size: 528px 341px;
      }

      &-rewind{
        background-position: -334px -132px;
      }

      &-forward{
        background-position: -264px -132px;
      }

      &-playpause{
        width: 132px;
        height: 132px;
        background-position: -396px 0;
        background-size: 528px 341px;

        &.paused{background-position: -264px 0;}
      }

      &-rewind, &-forward, &-playpause{
        opacity: 0.7;
        margin: 5px;

        &:active{opacity: 1;}
      }

      &, &-playing{
        button{
          border-radius: 50%;
          background-image: url('/img/sprite-v2.png');
          background-repeat: no-repeat;
        }
      }

      &-playing{
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: rgba(black, 0.6);
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-main{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0 0 30px;
        }

        &-secondary{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-bottom: -107px;
        }
      }
    }

    &-subtitle{
      left: 20px;
      right: 20px;
      bottom: 23px;
      z-index: 2;
      text-align: center;
      background-color: rgba(black, 0.7);
      color: white;
      padding: .5rem;
      font-family: "ff-speak-web", sans-serif;
      font-size: 1.2rem;
      font-weight:100;
    }

    &-progress{
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      z-index: 4;
      background-color: rgba(white, 0.35);

      &:before, &-bar{
        left: 50%;
        top: 0;
        height: 100%;
        transform: translateX(-50%);
        transition: width 0.2s ease;
      }

      &:before{
        content: "";
        position: absolute;
        background-color: white;
        z-index: 0;
        width: 0;
        transition: width 2s ease-out;
      }

      &-bar{
        position: relative;
        background-color: $primary;
        z-index: 1;
      }

      &.started{
        &:before{
          width: 100%;
        }
      }
    }

    @media (orientation: landscape) {
      &-controls{
        &-playing{
          &-main{
            justify-content: center;
            padding-bottom: 0;
          }

          &-secondary{
            margin-bottom: -10%;
          }
        }
      }
    }
  }
</style>
