<template lang="pug">
  Frame.Credits.scrollable(
    :style="`background-image: url(${images[0]});`",
    v-on="$listeners")
    .Credits-wrapper
      .Credits-text
        .block
          span.credit.title {{ $root.$t('information.directors') }}
          span.credit.name Beyza Boyacıoğlu & Jeff Soyk

          br

          span.credit.title {{ $root.$t('information.producer') }}
          span.credit.name Beyza Boyacıoğlu

          br

          span.credit.title {{ $root.$t('information.web_designer') }}
          span.credit.name Jeff Soyk

          br

          span.credit.title {{ $root.$t('information.mobile_developer') }}
          span.credit.name Sinan Mutlu

          br

          span.credit.title {{ $root.$t('information.desktop_developer') }}
          span.credit.name Can Usta

          br

          span.credit.title {{ $root.$t('information.exec-producers') }}
          span.credit.name Emine & Ahmet Boyacıoğlu, Debbie & Bill McGinness, Sam Onat Yılmaz

          br

          span.credit.title {{ $root.$t('information.co_producer_vojo_lead') }}
          span.credit.name Sasha Costanza-Chock

          br

          span.credit.title {{ $root.$t('information.vojo_development') }}
          span.credit.name Research Action Design

          br

          span.credit.title {{ $root.$t('information.legal_counsel') }}
          span.credit.name Duygu Türemez, Çağlar Kaçar

          br

          span.credit.title {{ $root.$t('information.archival_researcher') }}
          span.credit.name Ege Berensel

          br

          span.credit.title {{ $root.$t('information.editor') }}
          span.credit.name Defne Kırmızı

          br

          span.credit.title {{ $root.$t('information.promotional_materials') }}
          span.credit.name Hayrettin Günç

          //- span.credit.title Social Media Campaign
          //- span.credit.name PAZ

        .block
          h2 {{ $root.$t('information.special_thanks') }}
          p.desc {{ $root.$t('information.thanks_text') }}

        .block

          h2 {{ $root.$t('information.kickstarter_title') }}
          p.desc {{ $root.$t('information.kickstarter_backers') }}

        .block
          h2 {{ $root.$t('information.supported_by') }}

      .Credits-bottom
          .Credits-logo(:style="`background-image: url(${images[1]});`")

      .block

      .block
          p.desc {{ $root.$t('information.disclaimer_text-first') }}
          p.desc {{ $root.$t('information.disclaimer_text-second') }}

      .block
          p.desc {{ $root.$t('information.copyright') }}

</template>

<script>
export default {
  name: 'Credits',
  props: {
    images: { type: Array, default: () => ([]) }
  }
}
</script>

<style lang="scss">
  .Credits{
    padding-top: 56px;
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    display: flex !important;
    flex-direction: column;
    font-size: 1rem;
    overflow: auto;
    /*font-family: $font-family-lucida-regular;*/
    font-family:'Hind', sans-serif;
    line-height:1.0625rem;

  .Credits-bottom {
    width: 100%;
  }

  .Credits-logo {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 33%;
  }

    &-wrapper{
      padding: 30px 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
    }

    .block{
      font-size: 0.7rem;
      margin-bottom: 25px;
    }

    &-text{
      flex: 1;

      h2{
        font-size: 100%;
        /*text-decoration: underline;*/
        margin: 0 0 10px;
      }

      h2, .title{
        /*font-family: $font-family-cormorant;*/
        font-family: 'Cardo', sans-serif;
        font-weight: 900;
        text-transform: uppercase;
      }

      .title{
        font-size: 74%;
        color: #232323;
        padding: 0 5px 0 0;
        /*display: inline-block;*/
      }

      .name{
        font-size: 115%;
        /*padding: 0 5px;*/
        padding: 0 5px 0 0;
        /*display: inline-block;*/
      }

      .desc{font-size: 90%;line-height:.875rem;}
    }

    &-logo{
      width: 270px;
      height: 90px;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
</style>
