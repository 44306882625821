<template lang="pug">
  Frame.ALongOverduePoem(:class="{ playing, paused: !playing && (started || finished) }")
    .image.image-zeki
    .image.image-bottom-bar
    .image.image-stripe-bar
    .image.image-top-bar-filled
    .image.image-bottom-bar-filled
    .image.image-full-ad
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'ALongOverduePoem',
  mixins: [
    mixinScene({
      'zeki': i => ({
        from: 'transform: scale(2.25); transform-origin: 87% 33%;',
        to: 'transform: scale(1)',
        options: {
          easing: 'ease',
          duration: 7
        }
      }),
      'bottom-bar': i => ({
        from: 'clip-path: inset(100% 0 0 0);',
        to: 'clip-path: inset(70% 0 0 0);',
        options: {
          easing: 'ease-out',
          delay: 8,
          duration: 0.8
        }
      }),
      'stripe-bar': i => ({
        from: 'clip-path: inset(100% 0 0 0);',
        to: 'clip-path: inset(20% 0 0 0);',
        options: {
          easing: 'ease-out',
          delay: 8.2,
          duration: 1.75
        }
      }),
      'top-bar-filled': i => ({
        from: 'clip-path: inset(0 100% 0 0);',
        to: 'clip-path: inset(0 0 0 0);',
        options: {
          easing: 'ease-out',
          delay: 9.25,
          duration: 0.35
        }
      }),
      'bottom-bar-filled': i => ({
        from: 'clip-path: inset(0 0 0 100%);',
        to: 'clip-path: inset(0 0 0 0);',
        options: {
          easing: 'ease-out',
          delay: 9.5,
          duration: 0.35
        }
      }),
      'full-ad': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease-out',
          delay: 12,
          duration: 2
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 25.4672
        }
      })
    }, {
      selector: (selector) => `.ALongOverduePoem .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .ALongOverduePoem{
    background: url('/messages/msg_3/img/background.jpg') no-repeat center;
    background-size: cover;

    .image{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      &-zeki{
        z-index: 2;
        transform-origin: 89% 33%;
        background-image: url('/messages/msg_3/img/zeki.png');
      }

      &-bottom-bar{
        z-index: 3;
        background-image: url('/messages/msg_3/img/bottom-bar.jpg');
        background-size: 100% auto;
        background-position: center bottom;
      }

      &-stripe-bar{
        z-index: 1;
        background-image: url('/messages/msg_3/img/stripe-bar.jpg');
        background-size: 100% 34%;
        background-position: center 60%;
      }

      &-top-bar-filled{
        z-index: 1;
        background-image: url('/messages/msg_3/img/top-bar-filled.jpg');
        background-size: 100% auto;
        background-position: 0 0;
      }

      &-bottom-bar-filled{
        z-index: 3;
        background-image: url('/messages/msg_3/img/bottom-bar-filled.jpg');
        background-size: 100% auto;
        background-position: 0 bottom;
      }

      &-full-ad{
        z-index: 4;
        background-image: url('/messages/msg_3/img/full-ad.jpg');
        transform-origin: 89% 33%;
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
