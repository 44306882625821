<template lang="pug">
  Frame.TurkeyDeveloped(:class="{ playing, paused: !playing && (started || finished) }")
    .image.first(data-part)
      .image.before
    .image
      .image.after
    .image.idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'TurkeyDeveloped',
  mixins: [
    mixinScene({
      'first': i => ({
        0: 'height: 100%',
        1.5: 'height: 47%;',
        3: 'height: 47%;',
        to: 'height: 0%;',
        options: {
          easing: 'cubic-bezier(0.74, 0, 0.25, 1)',
          duration: 4.5
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1',
        options: {
          easing: 'linear',
          duration: 6.6752
        }
      })
    }, {
      selector: selector => `.TurkeyDeveloped .${selector}`
    })
  ]
}
</script>

<style lang="scss">
  .TurkeyDeveloped{
    overflow: hidden;

    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: auto 100%;
      // background-position: center -20px;
      background-position: center 0;
      background-repeat: no-repeat;
      background-color: #e7e7e7;
      overflow: hidden;

      &.idle{
        background-color: transparent;
      }
    }

    .first{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      overflow: hidden;
      transition: all 1s cubic-bezier(0.74, 0, 0.25, 1);
    }

    .before{
      height: 100vh;
      background-image: url('/messages/msg_27/img/img-suit.jpg');
      // background-position: center -30px;
    }

    .after{
      height: 100vh;
      background-image: url('/messages/msg_27/img/img-costume.jpg');
      // background-position: center -20px;
    }

    @media (orientation: landscape) {
      .image{
        background-size: auto 100%;
      }
    }

    &.playing, &.paused{
      .first{ transition: none; }
    }
  }
</style>
