<template lang="pug">
  Frame.ZekiInMyDreams(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 4
      .image(class="image-"+n, data-flickerFade)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'ZekiInMyDreams',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'opacity: 1;',
        to: 'opacity: 0;',
        options: {
          easing: 'linear',
          duration: 1
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.1
        }
      })
    }, {
      selector: selector => `.ZekiInMyDreams .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .ZekiInMyDreams{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      will-change: opacity;
      transition: all 1s ease;

      &-1{
        z-index: 1;
        background-position: center 10%;
        background-image: url('/messages/msg_30/img/ZekiDreams-Cover.jpg');
      }

      &-2{
        background-image: url('/messages/msg_30/img/ZekiDreams.gif');
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
