<template>
  <svg class="icon" xmlns="http://www.w3.org/2000/svg" :width='opts.width' :height='opts.height' viewBox="0 0 20 20">
    <path :fill="opts.color" d="M17 1h-14c-1.1 0-2 0.9-2 2v14c0 1.101 0.9 2 2 2h7v-7h-2v-2.475h2v-2.050c0-2.164 1.212-3.684 3.766-3.684l1.803 0.002v2.605h-1.197c-0.994 0-1.372 0.746-1.372 1.438v1.69h2.568l-0.568 2.474h-2v7h4c1.1 0 2-0.899 2-2v-14c0-1.1-0.9-2-2-2z"></path>
  </svg>
</template>

<script>
import icon from '@/mixins/icon'

export default {
  mixins: [icon()],
  data () {
    return {
      defaults: {
        color: '#1877f2',
        width: 16,
        height: 16
      }
    }
  }
}
</script>
