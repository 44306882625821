<template lang="pug">
  Frame.FatherAndDaughter(:class="{ playing, paused: !playing && (started || finished) }")
    - var n=1;
    while n <= 4
      .image(class="image-"+n)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'FatherAndDaughter',
  mixins: [
    mixinScene({
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 1,
          delay: 0.1
        }
      }),
      '3': i => ({
        from: 'left: -181%;',
        to: 'left: 0%;',
        options: {
          easing: 'ease',
          duration: 1,
          delay: 48
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linar',
          duration: 4,
          delay: 51
        }
      }),
      // This is dummy control for timeline
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 57.8672
        }
      })
    }, {
      selector: selector => `.FatherAndDaughter .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .FatherAndDaughter{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      $images: (
        "cassette-static.jpg",
        "cassette.gif",
        "cover.png",
        "cassette-on-rug.jpg"
      );

      @for $i from 1 through length($images) {
        &-#{$i} {
          background-image: url("/messages/msg_25/img/#{nth($images, $i)}");
        }
      }

      &-1{
          left: 0%;
          background-size: auto 100%;
          background-position: 45% center;
          width: 100%;
      }

      &-2{
          left: 0%;
          background-size: auto 100%;
          background-position: 45% center;
          width: 100%;
      }

      &-3{
          background-size: auto 100%;
          background-position: 0% center;
          width: 181%;
      }
    }
  }
</style>
