<template lang="pug">
  Frame.GeographyTeacher(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 4
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'GeographyTeacher',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'transform: scale(1.3);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease-out',
          duration: 20
        }
      }),
      '2': i => ({
        from: 'transform: scale(1.5);',
        to: 'transform: scale(1.2);',
        options: {
          easing: 'ease-out',
          duration: 28,
          delay: 12
        }
      }),
      '2[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 8,
          delay: 12
        }
      }),
      '3': i => ({
        from: 'transform: scale(1.3);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease-out',
          duration: 28,
          delay: 32
        }
      }),
      '3[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 8,
          delay: 32
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 31,
          delay: 50
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 97.424
        }
      })
    }, {
      selector: selector => `.GeographyTeacher .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .GeographyTeacher{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;
      transform-origin: 50% 32%;

      &-1{
        background-image: url('/messages/msg_2/img/boy-1.jpg');
      }
      &-2{
        transform-origin: 50% -6%;
        background-image: url('/messages/msg_2/img/boy-2.jpg');
      }
      &-3{
        transform-origin: 59% 26%;
        background-image: url('/messages/msg_2/img/boy-3-no-shadow.jpg');
      }
      &-4{
        background-image: url('/messages/msg_2/img/boy-3-with-shadow.jpg');
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
