<template lang="pug">
  Frame.ZekiMurenAtKucukCiftlikPark(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 3
      .image(class="image-"+ n, data-fadein)
      - n++
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'ZekiMurenAtKucukCiftlikPark',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'transform: scale(1);',
        to: 'transform: scale(1.5);',
        options: {
          easing: 'linear',
          duration: 17
        }
      }),
      '2[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 16
        }
      }),
      '2': i => ({
        from: 'transform: scale(2.5);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease-out',
          duration: 16,
          delay: 16
        }
      }),
      '3': i => ({
        from: 'transform: scale(1.3);',
        to: 'transform: scale(1.1);',
        options: {
          easing: 'ease-out',
          duration: 9,
          delay: 30
        }
      }),
      '3[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 1,
          delay: 30
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 1,
          delay: 39.1472
        }
      })
    }, {
      selector: (selector) => `.ZekiMurenAtKucukCiftlikPark .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .ZekiMurenAtKucukCiftlikPark{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      will-change: transform;
      background-position: center 80%;
      transition: all 1s ease;

      @for $i from 1 through 3 {
        &-#{$i} {
          background-image: url('/messages/msg_7/img/7_GazinoStory_KucukCiftlikPark_Image#{$i}0_L.jpg');
        }
      }

      &-1{transform-origin: 81% 6%;}
      &-2{transform-origin: 100% 70%;}
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
