<template lang="pug">
  Frame.NearDeathExperience(:class="{ playing, paused: !playing && (started || finished) }")
    - var n=0;
    while n <= 10
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'NearDeathExperience',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.01,
          delay: 2
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 6
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 12.5
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 19
        }
      }),
      '5': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 26
        }
      }),
      '6': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 27.5
        }
      }),
      '7': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 29
        }
      }),
      '8[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.05,
          delay: 33.5
        }
      }),
      '8': i => ({
        from: 'transform: scale(1);',
        to: 'transform: scale(1.7);transform-origin: 20% 75%;',
        options: {
          easing: 'linear',
          duration: 22,
          delay: 33.5
        }
      }),
      '9': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease-out',
          duration: 2,
          delay: 52
        }
      }),
      '10': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease-out',
          duration: 2,
          delay: 59
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 73.016
        }
      })
    }, {
      selector: selector => `.NearDeathExperience .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .NearDeathExperience{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      @for $i from 0 through 10 {
        &-#{$i} {
          @if $i == 8 {
            $i: 1;
          } @else if $i > 8 {
            $i: $i - 1;
          }
          background-image: url("/messages/msg_16/img/25_Near_Death_Experience_Still#{$i}0.jpg");
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.05s linear;
      }
    }

    @media (orientation: landscape) {
      .image{
        background-position: center;

        &:last-child{background-position: center 0;}
      }
    }
  }
</style>
