<template>
  <svg class="icon" xmlns="http://www.w3.org/2000/svg" :width='opts.width' :height='opts.height' viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd" :stroke="opts.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
      <path d="M1 1l13.8 13.8M1 14.8L14.8 1"/>
    </g>
  </svg>
</template>

<script>
import icon from '@/mixins/icon'

export default {
  mixins: [icon()],
  data () {
    return {
      defaults: {
        color: 'black',
        width: 16,
        height: 16
      }
    }
  }
}
</script>
