<i18n>
{
  "en": {
    "swipe-tip": "swipe to explore"
  },
  "tr": {
    "swipe-tip": "kaydır ve keşfet"
  }
}
</i18n>

<template lang="pug">
  .Home
    // First screen
    transition(name="fadeIn")
      SplashScreen(v-if="showSplash", :enterance="enterance", @enter="onEnterClick")

    transition(name="fadeInFast")
      AudioPlayer(
        ref="AudioPlayer",
        v-if="activeMessage >= 0 && !!currentMessage.sound",
        :debug="isDebug",
        :src="currentMessage.sound",
        :subtitle="currentMessage.subtitle",
        :message="currentMessage.message",
        :value="messages[activeMessage].status",
        v-model="messages[activeMessage].status")

    // Messages
    #swipe-continue-tip
      span {{ $t('swipe-tip') }}
    .swiper-container
      .swiper-wrapper
        Intro.swiper-slide(
          ref="Intro",
          v-if="!hideIntro",
          :debug="isDebug",
          :message-count="messages.length",
          @canplaythrough="canPlay",
          @swipe-up="onIntroFinish")
        template(v-if="loadMessages")
          .swiper-slide(
            v-for="(message, index) in messages",
            :key="index",
            v-bind="message.attrs || {}")
            Message(
              :class="{visible: isInVisibleRange(index) }",
              :message="message.template || message.subtitle",
              :images="message.images",
              :status="message.status",
              @swipe-up="swipeCreditsToUp",
              @swipe-down="swipeCreditsToUp")
</template>

<script>
import SplashScreen from './SplashScreen'
import Intro from './Intro'
import Swiper from 'swiper'
import subtitlesEN from '@/../public/messages/subtitles/en.json'
import subtitlesTR from '@/../public/messages/subtitles/tr.json'
import Message from './messages/index.vue'
import preloadImages from '@/utils/preloadImages'

const subtitles = {
  en: subtitlesEN,
  tr: subtitlesTR
}

export default {
  name: 'Home',
  props: {
    messages: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      isDebug: false, // If true, intro video skips. This option only for development
      showSplash: true,
      hideIntro: false,
      enterance: false,
      canSlide: false,
      isFinishIntro: false,
      swiper: null,
      activeMessage: -1,
      loadMessages: false,
      preloadCount: 2
    }
  },
  computed: {
    lang () {
      return this.$store.state.global.locale
    },
    currentMessage () {
      if (this.activeMessage < 0) return {}
      const activeSubtitles = subtitles[this.lang] || {}
      let message = this.messages[this.activeMessage]
      let name = ''
      let subtitle = []
      let description = ''

      if (message) {
        name = message.subtitle
        subtitle = (activeSubtitles[name] || {}).subtitle
        description = (activeSubtitles[name] || {}).description
      }

      return {
        ...(message || {}),
        name,
        subtitle,
        message: description
      }
    }
  },
  watch: {
    currentMessage (newVal) {
      let { showCaptions, captionUnlocked } = (newVal || {}).status || {}
      if (captionUnlocked) {
        this.$emit('input', !showCaptions)
      }
    }
  },
  methods: {
    isInVisibleRange (index) {
      let start = this.activeMessage - this.preloadCount
      let end = this.activeMessage + this.preloadCount
      return index >= start && index <= end
    },
    onEnterClick (e) {
      let intro = this.$refs.Intro
      intro.play()
      intro.videoObj.muted = false
      this.showSplash = false

      this.preloadByIndex()
      // this.onLoadWindow()

      this.$emit('input', true)
    },
    canPlay () {
      setTimeout(() => {
        this.enterance = true
      }, 6000)
    },
    onIntroFinish (isIntroFinish, index) {
      if (!this.canSlide && isIntroFinish) {
        this.loadMessages = true
        this.$nextTick(() => {
          this.initSwiper(index)
          this.canSlide = isIntroFinish
          localStorage.setItem('loaded', 'true')
          const Header = this.$parent.$refs.Header
          if (Header) Header.showToggleMenu = true
        })
      }
    },
    initSwiper (index) {
      let current = this.preloadByIndex(index)

      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        on: {
          slideChange: (swiper) => {
            // hide the swipe tip
            document.getElementById('swipe-continue-tip').style.opacity = 0
            // Reset player if playing when slide change
            let { status = null } = this.currentMessage || {}
            if (status) {
              if (status.started) {
                let player = this.$refs.AudioPlayer
                player && player.reset()
              }
            }

            // Set active message
            this.activeMessage = swiper.realIndex
          },
          slideChangeTransitionEnd: () => {
            // Hide intro after first load
            if (!this.hideIntro) {
              this.hideIntro = true
              let current = this.preloadByIndex(index)
              this.swipeTo(current, 0)
              this.swiper.update()
            }
          }
        }
      })

      this.swipeTo(current + (this.hideIntro ? 0 : 1))
      window.location.hash = ''
    },
    swipeCreditsToUp (e) {
      const { direction, el } = e
      const { scrollHeight, offsetHeight } = el
      const canScrollable = scrollHeight > offsetHeight

      if (direction === 'down' && (!canScrollable || el.scrollTop <= 0)) {
        const speed = ((this.swiper || {}).params || {}).speed || 300
        this.swiper.slidePrev(speed)
        // Set index of last message.
        this.activeMessage = this.messages.length - 2
      }
    },
    getFrameFromUrl () {
      let hash = window.location.hash
      if (/credits$/.test(hash)) return hash.replace(/#/g, '')
      let frame = parseFloat(hash.replace(/#f/, ''))
      return isNaN(frame) ? 0 : frame
    },
    swipeTo (index, speed) {
      if (this.swiper) {
        let { status = null } = this.currentMessage || {}
        if (status) {
          const player = this.$refs.AudioPlayer
          if (player) {
            if (status.captionUnlocked) {
              this.$refs.AudioPlayer.replay()
            } else {
              this.$refs.AudioPlayer.reset()
            }
          }
        }
        this.swiper.slideTo(index, speed)
        this.activeMessage = index
      }
    },
    onHashChange (e) {
      if (!window.location.hash) return
      let frame = this.getFrameFromUrl()
      if (frame === 'credits') frame = this.messages.length - 1
      this.swipeTo(frame)
    },
    goToFrame (index) {
      index = index + (this.hideIntro ? 0 : 1)
      if (!this.loadMessages) {
        this.onIntroFinish(true, index)
      } else {
        this.swipeTo(index)
      }
    },
    preloadByIndex (index) {
      let frame = this.getFrameFromUrl()
      let messageLength = this.messages.length
      if (frame === 'credits') return messageLength
      let current = !isNaN(frame) && frame <= messageLength
        ? frame
        : this.activeMessage

      if (!isNaN(index)) current = index
      if (current < 0) current = 0
      if (current > 0) current -= 1

      let firstLoadMessages = this.messages.slice(current, current + this.preloadCount)
        .reduce((acc, msg) => {
          acc.push(...msg.images)
          return acc
        }, [])

      preloadImages(firstLoadMessages)
      return current
    },
    onLoadWindow () {
      let images = this.messages.reduce((acc, msg) => {
        acc.push(...msg.images)
        return acc
      }, [])
      preloadImages(images)
    }
  },
  beforeDestroy () {
    if (this.swiper) this.swiper.destroy()
    if (this.isDebug) {
      window.removeEventListener('hashchange', this.onHashChange)
    }
  },
  mounted () {
    if (this.isDebug) {
      window.addEventListener('hashchange', this.onHashChange)
    }
  },
  components: {
    SplashScreen,
    Intro,
    Message
  }
}
</script>

<style lang="scss">
  #swipe-continue-tip{
    position: absolute;
    /*width: 100%;*/
    left: 50%;
    transform: translate(-50%);
    bottom: 170px;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2.1px;
    font-size: 1rem;
    font-weight: 100;
    pointer-events: none;
    opacity: 0;
    transition: .75s;
    transition-delay: .25s;
    padding: 10px 15px;
    background-color: rgba(0,0,0,.66);
    /*-webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .75));
    filter: drop-shadow( 2px 1px 1px rgba(0, 0, 0, .75));
    text-shadow:1px 1px 10px rgba(0,0,0,.2), 1px 1px 10px rgba(0,0,0,.2);*/

    .icon{
      margin-top: 5px;
      position: absolute;
      top: -40px;
      width: 20px;
      height: 20px;
      -webkit-filter: drop-shadow( 2px 1px 6px rgba(0, 0, 0, .5));
      filter: drop-shadow( 2px 1px 6px rgba(0, 0, 0, .5));
    }
  }

  .Home{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    background-color: black;

    .swiper-container{
      width: 100%;
      height: 100%
    }

    .Message{
      display: none;

      &.visible{display: block;}
    }
    .swiper-wrapper{height: 100%;}
  }
</style>
