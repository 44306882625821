import Scene from 'scenejs'

function mixinScene (properties, options) {
  return {
    props: {
      images: { type: Array, default: () => ([]) },
      status: { type: Object, default: () => ({}) }
    },
    data () {
      return {
        scene: null
      }
    },
    computed: {
      duration () {
        return this.status.duration || 0
      },
      currentTime () {
        return this.status.currentTime || 0
      },
      started () {
        return this.status.started
      },
      finished () {
        return this.status.finished
      },
      playing () {
        return this.status.playing
      }
    },
    watch: {
      currentTime (current, old) {
        let diff = Math.round(current - old)
        let duration = this.duration
        let sceneDuration = this.scene.getDuration()

        // Convert positive number
        if (diff < 0) diff = diff * -1

        if (
          duration === 0 || // Reset
          current === 0 || // Rewinded to start
          current >= duration // Forwarded
        ) diff = 11 // Force to skip

        if (diff >= 10) {
          if (current > sceneDuration) {
            current = sceneDuration
          }
          this.scene.setTime(current)
        }
      },
      playing (newVal) {
        if (newVal) {
          this.scene.play()
        } else {
          this.scene.pause()
        }
      }
    },
    methods: {
      initScene () {
        this.scene = new Scene(properties, options)
        // Initialize first state
        this.scene.setTime(0)
      }
    },
    mounted () {
      this.initScene()
    }
  }
}

export default mixinScene
