import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import global from './global'

Vue.use(Vuex)

const softPersistence = new VuexPersist({
  storage: localStorage,
  modules: [
    'global'
  ]
})

export default new Vuex.Store({
  plugins: [
    softPersistence.plugin
  ],
  modules: {
    global
  }
})
