<template lang="pug">
  Frame.NeverTurnedHisBackOnStage(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n < 5
      .image(class="image-"+ n, data-block)
      - n++
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'NeverTurnedHisBackOnStage',
  mixins: [
    mixinScene({
      '2': i => ({
        from: 'opacity: 0',
        to: 'opacity: 1',
        options: {
          easing: 'ease-out',
          duration: 0.05,
          delay: 6.5
        }
      }),
      '3': i => ({
        from: 'opacity: 0',
        to: 'opacity: 1',
        options: {
          easing: 'ease-out',
          duration: 0.05,
          delay: 10
        }
      }),
      '4[data-block]': i => ({
        from: 'opacity: 0',
        to: 'opacity: 1',
        options: {
          easing: 'ease-out',
          duration: 0.05,
          delay: 14
        }
      }),
      /* '4': i => ({
        from: 'transform: scale(1.1);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease-out',
          duration: 3,
          delay: 14
        }
      }), */
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 18.4112
        }
      })
    }, {
      selector: (selector) => `.NeverTurnedHisBackOnStage .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .NeverTurnedHisBackOnStage{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      @for $i from 1 through 4 {
        &-#{$i} {
          background-image: url('/messages/msg_4/img/6_NeverTurnedHisBack_Wide_Still#{$i}0.jpg');
        }
      }
    }

    @media (orientation: landscape) {
      .image{
        background-position: center;

        &:last-child{background-position: center 0;}
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.05s linear;
      }
    }
  }
</style>
