<template lang="pug">
  Frame.DeceasedMother(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 4
      .image(class="image-"+n)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'DeceasedMother',
  mixins: [
    mixinScene({
      '1': i => ({
        'from': 'opacity: 1;',
        'to': 'opacity: 0;',
        options: {
          easing: 'linear',
          duration: 0.01
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 3,
          delay: 0.5
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 13,
          delay: 6
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 17,
          delay: 18
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 31.8032
        }
      })
    }, {
      selector: selector => `.DeceasedMother .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .DeceasedMother{
    background-color: white;

    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: 100%;
      background-color: #b9b9c1;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      $images: (
        "polaroid-black.jpg",
        "polaroid-white.jpg",
        "polaroid-family.jpg",
        "polaroid-zeki.jpg"
      );

      &-idle{background-color: transparent;}

      @for $i from 1 through length($images) {
        &-#{$i} {
          background-image: url("/messages/msg_26/img/#{nth($images, $i)}");
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.05s linear;
      }
    }
  }
</style>
