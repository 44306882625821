<template lang="pug">
  Frame.WhyArmy(:class="{ playing, paused: !playing && (started || finished) }")
    .image.image-1
    //- .image.image-2(data-effect="fadeOut")
    .image.image-3(data-effect="fadeOut")
    .image.image-4
    .image.image-4(data-effect="fadeOut")
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'WhyArmy',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'transform: scale(1.8); transform-origin: 50% 15%;',
        to: 'transform: scale(1); transform-origin: 50% 0%;',
        options: {
          easing: 'ease-out',
          duration: 9.5
        }
      }),
      '3': i => ({
        from: 'transform: scale(1.8); transform-origin: 50% 15%;',
        to: 'transform: scale(1); transform-origin: 50% 0%;',
        options: {
          easing: 'ease-out',
          duration: 9.5
        }
      }),
      '3[data-effect="fadeOut"]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 5.5,
          delay: 4
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1',
        options: {
          easing: 'linear',
          duration: 2,
          delay: 9.5
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1',
        options: {
          easing: 'linear',
          duration: 1,
          delay: 12.176
        }
      })
    }, {
      selector: (selector) => `.WhyArmy .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .WhyArmy{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 1s ease;

      @for $i from 1 through 4 {
        &-#{$i}{
          z-index: $i;
          background-image: url("/messages/msg_21/img/image-#{$i}.jpg");
        }
      }

      &-1{transform: scale(1.2);}
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
