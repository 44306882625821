export default () => {
  const str = window.location.href.split('?')[1]
  if (!str) return {}
  const queryString = str.replace(window.location.hash || '', '')
  const queryStringParams = queryString.split('&')
  const queryStringObject = {}
  queryStringParams.forEach(i => {
    const keyVal = i.split('=')
    queryStringObject[keyVal[0]] = keyVal[1]
  })

  return queryStringObject
}
