<template lang="pug">
  Frame.DamnWithAllTheseGuys(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 0;
    while n <= 4
      .image(class="image-" + n)
      - n++
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'DamnWithAllTheseGuys',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 2.75
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 7.75
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 13.25
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 17
        }
      }),
      // This is dummy control for timeline
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 20.4272
        }
      })
    }, {
      selector: selector => `.DamnWithAllTheseGuys .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .DamnWithAllTheseGuys{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @for $i from 0 through 4 {
        &-#{$i} {
          background-image: url('/messages/msg_10/img/10_Damn_With_All_These_Guys_#{$i}0.jpg');
        }
      }
    }
  }
</style>
