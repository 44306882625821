<i18n>
{
  "en": {
    "contact": "CONTACT",
    "about_text": "<p>Zeki Müren Hotline started as a simple hotline in 2015, collecting everyday people’s messages to and about Zeki Müren — Turkey’s most beloved and equally controversial pop star. An homage to the intimacy Müren established with his fans, as well as a throwback to the 1990’s hotline phenomenon, this participatory project quickly became a sensation in Turkey. During the few months it was active, the hotline received hundreds of messages, often expressing nostalgia for the deceased icon and Turkey’s bygone days. The Zeki Müren Hotline web experience presents a selection from those messages.</p><p>Explore Zeki Müren’s life, work and legacy, through the eyes of his fans.</p>"
  },
  "tr": {
    "contact": "İLETİŞİM",
    "about_text": "<p>Zeki Müren Hotline started as a simple hotline in 2015, collecting everyday people’s messages to and about Zeki Müren — Turkey’s most beloved and equally controversial pop star. An homage to the intimacy Müren established with his fans, as well as a throwback to the 1990’s hotline phenomenon, this participatory project quickly became a sensation in Turkey. During the few months it was active, the hotline received hundreds of messages, often expressing nostalgia for the deceased icon and Turkey’s bygone days. The Zeki Müren Hotline web experience presents a selection from those messages.</p><p>Explore Zeki Müren’s life, work and legacy, through the eyes of his fans.</p>"
  }
}
</i18n>
<template lang="pug">
  transition(name="fadeInFast", mode="out-in")
    .About(v-if="show")
      button.About-close(@click.stop.prevent="close")
        Icon(name="Close", color="currentColor")

      .About-content
        img.laurel(src="/img/laurels.png", alt="Laurels")
        template(v-html="$t('about_text')")

        h3 {{ $root.$t('information.credits') }}
        div #[span.title {{ $root.$t('information.directors') }}]: Beyza Boyacıoğlu & Jeff Soyk
        div #[span.title {{ $root.$t('information.producer') }}]: Beyza Boyacıoğlu
        div #[span.title {{ $root.$t('information.web_designer') }}]: Jeff Soyk
        div #[span.title {{ $root.$t('information.mobile_developer') }}]: Sinan Mutlu
        div #[span.title {{ $root.$t('information.desktop_developer') }}]: Can Usta
        div #[span.title {{ $root.$t('information.exec-producers') }}]: Debbie & Bill McGinness, Emine & Ahmet Boyacıoğlu, Sam Onat Yılmaz
        div #[span.title {{ $root.$t('information.co_producer_vojo_lead') }}]: Sasha Costanza-Chock
        div #[span.title {{ $root.$t('information.vojo_development') }}]: Research Action Design
        div #[span.title {{ $root.$t('information.legal_counsel') }}]: Duygu Türemez, Çağlar Kaçar
        div #[span.title {{ $root.$t('information.archival_researcher') }}]: Ege Berensel
        div #[span.title {{ $root.$t('information.editor') }}]: Defne Kırmızı
        div #[span.title {{ $root.$t('information.promotional_materials') }}]: Hayrettin Günç
        //- div #[span.title Social Media Campaign]: PAZ

        h3 {{ $root.$t('information.special_thanks') }}
        p {{ $root.$t('information.thanks_text') }}

        h3 {{ $root.$t('information.kickstarter_title') }}
        p {{ $root.$t('information.kickstarter_backers') }}

        h3 {{ $root.$t('information.supported_by') }}

        img(src="/messages/credit/img/logos-credits2.png", alt="Credits Logo")

        h3 {{ $t('contact') }}
        div
          a(href="mailto:zekimurenhotline@gmail.com") zekimurenhotline@gmail.com

        h3 {{ $root.$t('information.research_resources') }}
        div
          a(href="https://docs.google.com/document/d/1t2aspHtdYoJ8I1uNfYhq0TZcFna2olZPz_fLWwroifc/edit?usp=sharing") {{ $root.$t('information.resources_text') }}

        p {{ $root.$t('information.disclaimer_divider') }}
        p {{ $root.$t('information.disclaimer_text-first') }}
        p {{ $root.$t('information.disclaimer_text-second') }}

        p.copyrightText {{ $root.$t('information.copyright') }}
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      show: false
    }
  },
  methods: {
    close () {
      this.show = false
    },
    open () {
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .About{
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: black;
    z-index: 20;
    font-size: 1rem;
    /*font-family: sans-serif;*/
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /*font-family: sans-serif;*/
    /*font-family: "proxima-nova", sans-serif;*/
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;

    &-close{
      border: 0;
      color: white;
      padding: 20px !important;
      background: transparent;

      .icon{
        width: 25px;
        height: 25px;
        display: block;
      }
    }

    &-content{
      width: 100%;
      flex: 1;
      overflow: auto;
      padding: 0 30px 30px;

      h3{
        /*text-decoration: underline;*/
        margin-bottom: 10px;
        margin-top: 30px;
      }

      .title{font-weight: bold;}

      a{color: currentColor;}

      .copyrightText {
        margin-top:50px;
        text-align:center;
        font-size:.75em;
        font-size:.75rem;
      }

      img{
        width: 100%;
        /*max-width: 270px;*/
        margin: 0 auto;
        margin-top:50px;
        margin-bottom:50px;
        display: block;

        &.laurel{
          margin-left: 0;
          margin-right: 0;
          margin-top:30px;
          margin-bottom:50px;
        }
      }
    }
  }
</style>
