import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home/router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    Home,
    {
      path: '*',
      redirect: '/'
    }
  ]
})

export default router
