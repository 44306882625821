<i18n>
{
  "en": {
    "about": "ABOUT"
  },
  "tr": {
    "about": "HAKKINDA"
  }
}
</i18n>

<template lang="pug">
  .Header(:class="{ open }")
    .Header-top
      .Header-toggle(v-if="showToggleMenu")
        .navIcon(@click="toggleMenu", :class="{ open }")
          span
          span

      .Header-languages(v-if="open")
        button(
          v-for="(name, code) in languages",
          :key="code",
          :class="{ selected: code === lang }",
          @click="setLanguage(code)") {{ code.toUpperCase() }}

    .Header-content
      nav.Header-nav
        a.Header-nav-item(
          href="javascript:void(0);",
          @click="showAboutPopup") {{ $t('about') }}
      nav.Header-grid(v-if="open")
        a.Header-grid-item(
          v-for="(message, index) in messages",
          v-if="message.menu !== false",
          :key="index",
          :class="{ visited: !!(message.status || {}).captionUnlocked }"
          :href="`javascript:void(0);`",
          @click="goToMessage(index)")
          img(:src="message.menu", :alt="message.subtitle")
</template>

<script>
export default {
  name: 'Header',
  props: {
    messages: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      showToggleMenu: false,
      open: false
    }
  },
  computed: {
    lang () {
      return this.$store.state.global.locale
    },
    languages () {
      let messages = this.$root.$i18n.messages
      return Object.keys(messages).reduce((acc, lang) => {
        acc[lang] = messages[lang].lang_name
        return acc
      }, {})
    }
  },
  methods: {
    setLanguage (code) {
      this.$store.dispatch('global/setLocale', code)
    },
    toggleMenu () {
      this.open = !this.open
    },
    goToMessage (index) {
      this.toggleMenu()
      const Home = ((this.$route.matched[0] || {}).instances || {}).default
      if (Home) Home.goToFrame(index)
    },
    showAboutPopup () {
      this.$root.$children[0].$refs.About.open()
      this.toggleMenu()
    }
  },
  mounted () {
    let isLoadedAlready = !!localStorage.getItem('loaded')
    this.showToggleMenu = isLoadedAlready
  }
}
</script>

<style lang="scss">
  .Header{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    transition: background-color 0.2s linear;

    .navIcon{
      $height: 30px;
      $lineHeight: 4px;

      pointer-events: auto;
      width: 40px;
      height: $height;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:before,
      &:after{content: "";}

      &:before, &:after, span{
        display: block;
        position: absolute;
        height: $lineHeight;
        width: 100%;
        background-color: white;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
      }

      &:before{top: 0px;}
      &:after{top: $height - $lineHeight;}

      span{
        &:nth-child(1),
        &:nth-child(2) {top: (($height / 2) - ($lineHeight / 2));}
      }

      &.open{
        &:before, &:after{
          top: 18px;
          width: 0%;
          left: 50%;
        }
        span{
          &:nth-child(1) {transform: rotate(45deg);}
          &:nth-child(2) {transform: rotate(-45deg);}
        }
      }
    }

    &-content{
      flex: 1;
      overflow: auto;
      background-color: black;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s linear;

      a{-webkit-tap-highlight-color: transparent;}
    }

    &-top{display: flex;}
    &-toggle{padding: 25px;}
    &-languages{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      pointer-events: auto;

      button{
        border: 0;
        padding: 10px 15px;
        color: white;
        background-color: transparent;
        font-family: $font-family;
        outline: none;

        &:active, &.selected{
          background-color: rgba($primary, 0.5);
        }
      }
    }

    &-nav{
      background-color: #323232;

      &-item{
        padding: 25px;
        border-top: 1px solid black;
        color: white;
        text-decoration: none;
        display: block;
        line-height: normal;

        &:active, &:focus{
          background-color: rgba($primary, 0.5);
        }
      }
    }

    &-grid{
      display: flex;
      flex-wrap: wrap;

      &-item{
        width: (100% / 3);
        height: 200px;
        display: block;
        overflow: hidden;
        position: relative;
        border: 1px solid black;

        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.2s linear;
          background-color: $primary;
        }

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.5;
        }

        &:active, &:focus{
          &:before{opacity: 0.7;}
        }

        &.visited{
          img{opacity: 1;}
        }
      }
    }

    &.open{
      background-color: #323232;

      .Header{
        &-content{
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    @media (orientation: landscape) {
      &-grid{
        &-item{height: 265px;}
      }
    }
  }
</style>
