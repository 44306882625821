<template lang="pug">
  Frame.CantFindLove(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 4
      .image(class="image-"+n, data-innershadow)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'CantFindLove',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'box-shadow: inset 0 0 135px 100px black;',
        to: 'box-shadow: inset 0 0 0 0 black;',
        options: {
          easing: 'linear',
          duration: 3
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease-out',
          duration: 3,
          delay: 13
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease-out',
          duration: 3,
          delay: 28
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease-out',
          duration: 3,
          delay: 37
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 50.912
        }
      })
    }, {
      selector: selector => `.CantFindLove .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .CantFindLove{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      will-change: opacity;
      transition: all 3s ease;

      @for $i from 1 through 4 {
        &-#{$i} {
          background-image: url("/messages/msg_14/img/15_Cant_Find_Love_Still#{$i}0.jpg");
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }

    @media (orientation: landscape) {
      .image{
        background-position: center 70%;
      }
    }
  }
</style>
