<template lang="pug">
  Frame.WhyThoseOutfits(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 6
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'WhyThoseOutfits',
  mixins: [
    mixinScene({
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 5
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 12
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 17.5
        }
      }),
      '5': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 22
        }
      }),
      '6': i => ({
        from: 'transform: scale(1.15);',
        to: 'transform: scale(1);',
        options: {
          duration: 10.6392,
          delay: 27.5
        }
      }),
      '6[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          delay: 27.5,
          duration: 0.01
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 38.1392
        }
      })
    }, {
      selector: selector => `.WhyThoseOutfits .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .WhyThoseOutfits{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      @for $i from 1 through 6 {
        &-#{$i} {
          @if $i == 5 {
            $i: "4_2";
          } @else if $i > 5 {
            $i: $i - 1;
          }
          background-image: url('/messages/msg_8/img/10_Why_Those_Outfits_Image#{$i}_L.jpg');
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.01s linear;
      }
    }
  }
</style>
