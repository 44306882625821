<template lang="pug">
  .Frame
    slot
</template>

<script>
export default {
  name: 'Frame',
  data () {
    return {
      xDown: null,
      yDown: null
    }
  },
  methods: {
    getTouches (evt) {
      return evt.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      if (!this.xDown || !this.yDown) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          if (this.$listeners.hasOwnProperty('swipe-left')) {
            this.$emit('swipe-left', { direction: 'left', el: this.$el, event: evt })
          }
        } else {
          if (this.$listeners.hasOwnProperty('swipe-right')) {
            this.$emit('swipe-right', { direction: 'right', el: this.$el, event: evt })
          }
        }
      } else {
        if (yDiff > 0) {
          if (this.$listeners.hasOwnProperty('swipe-up')) {
            this.$emit('swipe-up', { direction: 'up', el: this.$el, event: evt })
          }
        } else {
          if (this.$listeners.hasOwnProperty('swipe-down')) {
            this.$emit('swipe-down', { direction: 'down', el: this.$el, event: evt })
          }
        }
      }
      this.xDown = null
      this.yDown = null
    }
  },
  beforeDestroy () {
    this.$el.removeEventListener('touchstart', this.handleTouchStart)
    this.$el.removeEventListener('touchmove', this.handleTouchMove)
  },
  mounted () {
    this.$el.addEventListener('touchstart', this.handleTouchStart)
    this.$el.addEventListener('touchmove', this.handleTouchMove)
  }
}
</script>

<style lang="scss">
  .Frame {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
    background-color: black;
  }
</style>
