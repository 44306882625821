<i18n>
{
  "tr": {
    "continue": "kaydır",
    "swipe_to_explore": "kaydır ve keşfet",
    "summary": [
      "Türkiye’nin ilk popstarı, sanat müziği üstadı ve sıradışı ikonu Zeki Müren, 1996 senesinde vefat etti.",
      "Ölümünden 19 sene sonra kurulan Zeki Müren Hattı, Müren ile dertleşmek isteyenlerin telefon yağmuruna tutuldu.",
      "Birazdan keşfedeceğiniz web deneyimi, Zeki Müren Hattı’na bırakılan yüzlerce anonim mesajdan bir seçki sunuyor."
    ]
  },
  "en": {
    "continue": "swipe",
    "swipe_to_explore": "swipe to explore",
    "summary": [
      "Modern Turkey’s first pop star, <br/>Zeki Müren, died in 1996.",
      "Tens of thousands attended his state funeral in national mourning.",
      "To this day, he is considered the greatest Turkish singer and fondly remembered with his nickname<br/>“The General.”",
      "19 years after his death, <em>Zeki Müren Hotline</em> invited anyone to leave a message for Zeki — resulting in hundreds of calls.",
      "The following is a selection of messages left by the anonymous callers."
    ]
  }
}
</i18n>

<template lang="pug">
  Frame.Intro(
    @swipe-up="onSwipeUp",
    @swipe-down="onSwipeDown")
    transition-group.Intro-texts(:name="slideName", mode="out-in", tag="div")
      .text(
        v-for="(line, index) in summary",
        :key="line",
        v-if="currentLine === index",
        v-html="line")

    transition(name="fadeIn")
      .Intro-swipe(
        v-if="show.continue || show.swipe",
        :aria-label="$t(show.continue ? 'continue' : 'swipe_to_explore')")
        span {{ $t(show.continue ? 'continue' : 'swipe_to_explore') }}
        Icon(name="ArrowDown", color="currentColor")

    video.Intro-video#IntroVideo(
      autoplay,
      muted,
      playsinline,
      poster="/img/poster-v2.png",
      preload="auto",
      @canplaythrough="oncanplaythrough",
      @loadstart="onloadstart",
      @progress="onprogress")
      source#videoSource(:src="video", type="video/mp4")
      |Your browser does not support the video element.
</template>

<script>
export default {
  name: 'Intro',
  props: {
    messageCount: { type: Number, default: 0 },
    debug: { type: Boolean }
  },
  data () {
    return {
      canplayCallback: null,
      continueTimeout: null,
      fadeDownVideoMusic: null,
      fadeUpVideoMusic: null,
      currentLine: -1,
      videoObj: null,
      video: '/videos/20210210_V14_Intro_Small.mp4',
      loadedPercent: 0,
      canSwipe: false,
      slideName: 'fadeIn',
      isIntroFinish: false,
      show: {
        continue: false,
        swipe: false
      }
    }
  },
  computed: {
    summary () {
      let summary = this.$t('summary') || {}
      return Object.keys(summary).map(k => {
        return summary[k].replace(/\{count\}/, this.messageCount)
      })
    }
  },
  methods: {
    onSwipeUp (e) {
      if (!this.canSwipe) return false
      this.show.continue = false
      this.slideName = 'fadeSlider'
      clearTimeout(this.continueTimeout)
      if (this.currentLine < this.summary.length) {
        this.currentLine += 1
      } else {
        this.currentLine = this.summary.length
      }

      if (this.currentLine === this.summary.length - 1) {
        // Show swipe to explore info
        setTimeout(() => {
          this.show.swipe = true
          this.isIntroFinish = true
        }, 1000)
      } else {
        this.continueTimeout = setTimeout(() => {
          this.show.continue = true
        }, 4000)
      }

      if (this.currentLine === this.summary.length) {
        // Pause video when end of the slide
        this.videoObj.pause()
        clearInterval(this.fadeDownVideoMusic)
      }

      this.$emit('swipe-up', this.isIntroFinish)
    },
    onSwipeDown () {
      if (
        !this.canSwipe ||
        this.currentLine <= 0
      ) return false
      this.show.continue = false
      this.isIntroFinish = false
      this.show.swipe = false
      this.slideName = 'fadeSliderDown'

      clearTimeout(this.continueTimeout)
      if (this.currentLine > 0) {
        this.currentLine -= 1
      } else {
        this.currentLine = 0
      }

      if (this.currentLine === this.summary.length - 1) {
        // Show swipe to explore info
        setTimeout(() => {
          this.show.swipe = true
          this.isIntroFinish = true
        }, 1000)
      } else {
        this.continueTimeout = setTimeout(() => {
          this.show.continue = true
        }, 4000)
      }

      this.$emit('swipe-down', this.isIntroFinish)
    },
    onloadstart () {
      this.$emit('loadstart', this.videoObj)
    },
    play () {
      if (this.debug) {
        this.canSwipe = true
        this.show.continue = false
        this.show.swipe = true
        this.isIntroFinish = true
        this.currentLine = this.summary.length
        this.videoObj.pause()

        this.$emit('swipe-up', this.isIntroFinish)
        return false
      }

      this.videoObj.play()

      // Show first line after 10s
      setTimeout(() => {
        this.currentLine = 0

        // Active swipe actions after 3s
        setTimeout(() => {
          this.canSwipe = true
          this.show.continue = true
          this.slideName = 'fadeSlider'
        }, 1000)
      }, 10000)
    },
    pause () {
      this.videoObj.pause()
    },
    onprogress (e) {
      let { duration, buffered } = this.videoObj
      let bufferTotal = 0
      for (let i = 0; i < buffered.length; i++) {
        bufferTotal += buffered.end(0)
      }
      this.loadedPercent = parseInt((bufferTotal / duration) * 100)
    },
    oncanplaythrough () {
      clearTimeout(this.canplayCallback)
      this.$emit('canplaythrough', this.videoObj)
    }
  },
  mounted () {
    this.videoObj = document.getElementById('IntroVideo')
    this.videoObj.currentTime = 0
    this.pause()

    /// Trigger event manually if cannot run
    /* this.canplayCallback = setTimeout(() => {
      this.$emit('canplaythrough', this.videoObj)
    }, 5000) */
  }
}
</script>

<style lang="scss" scoped>
  .Intro{
    &-texts, &-video, &-swipe{
      pointer-events: none;
    }

    &-swipe{
      position: absolute;
      width: 100%;
      bottom: 50px;
      z-index: 3;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 2.1px;
      font-size: 1rem;
      font-weight: 100;

      .icon{
        margin-top: 5px;
        position:absolute;
        top:-40px;
        width: 20px;
        height: 20px;
      }
    }

    .text{
      text-align: center;
      font-weight: 100;
      padding: 1.2em;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 3;
      padding: 20px;
    }

    &-video{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
      border: 0;
      background-color: black;
      pointer-events: none;
      transition: opacity 6s;
    }

    .fadeSliderDown {
      &-enter-active,
      &-leave-active {
        transition: top 1.2s ease, opacity 1.2s linear;
      }

      &-enter{
        top: 40% !important;
        opacity: 0;
      }

      &-enter-to {transition-delay: 1s;}

      &-enter-to,
      &-leave{
        top: 50% !important;
        opacity: 1;
      }

      &-leave-to{
        top: 60% !important;
        opacity: 0;
      }
    }
  }
</style>
