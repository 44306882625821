<template lang="pug">
  Frame.BestThing(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 4
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'BestThing',
  mixins: [
    mixinScene({
      '2[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease',
          duration: 1,
          delay: 0.1
        }
      }),
      '2': i => ({
        from: 'transform: scale(2);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 47
        }
      }),
      '3': i => ({
        from: 'transform: scale(3);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 47,
          delay: 0.1
        }
      }),
      '3[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease',
          duration: 2,
          delay: 0.1
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'ease',
          duration: 2,
          delay: 47
        }
      }),
      // This is dummy control for timeline
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 55.016
        }
      })
    }, {
      selector: selector => `.BestThing .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .BestThing{
    overflow: hidden;

    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      $images: (
        "still.jpg",
        "background.jpg",
        "zeki.png",
        "zeki-name.png"
      );

      @for $i from 1 through 4 {
        &-#{$i} {
          background-image: url("/messages/msg_18/img/#{nth($images, $i)}");
        }
      }

      &-3{transform-origin: 0 20%;}
      &-4{
        background-size: 70% auto;
        background-position: center 7%;
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
