<template lang="pug">
  Frame.SomeoneAsLonelyAsYou(:class="{ playing, paused: !playing && (started || finished) }")
    - var n=1;
    while n <= 2
      .image(class="image-"+n, data-fadein)
      - n++;
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'SomeoneAsLonelyAsYou',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'transform: scale(1.5);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease-out',
          duration: 22
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 5,
          delay: 14
        }
      }),
      // This is dummy control for timeline
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 21.604344
        }
      })
    }, {
      selector: selector => `.SomeoneAsLonelyAsYou .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .SomeoneAsLonelyAsYou{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      @for $i from 1 through 2 {
        &-#{$i} {
          background-image: url("/messages/msg_6/img/SomeoneAsLonelyAsYou_Still_#{$i}.jpg");
        }
      }

      &-1{transform-origin: 60% 90%;}
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
