export default function preloadImages (array = [], success) {
  if (!preloadImages.list) {
    preloadImages.list = []
  }
  var list = preloadImages.list
  let loaded = 0
  for (var i = 0; i < array.length; i++) {
    if (!array[i]) return
    var img = new Image()
    img.onload = function () {
      var index = list.indexOf(this)
      if (index !== -1) {
        // remove image from the array once it's loaded
        // for memory consumption reasons
        list.splice(index, 1)
        loaded += 1

        if (loaded === array.length) {
          if (typeof success === 'function') {
            success(this)
          }
        }
      }
    }
    list.push(img)
    img.src = array[i]
  }
}
