<template lang="pug">
  Frame.TaughtMeHowtoRestMySoul(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 2
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'TaughtMeHowtoRestMySoul',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'transform: scale(2.2);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 40
        }
      }),
      '2': i => ({
        from: 'transform: scale(2.2);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 40
        }
      }),
      '2[data-fadein]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 30,
          delay: 10
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 41.2352
        }
      })
    }, {
      selector: selector => `.TaughtMeHowtoRestMySoul .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .TaughtMeHowtoRestMySoul{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;
      transform-origin: 54% 0;

      @for $i from 1 through 2 {
        &-#{$i} {
          background-image: url("/messages/msg_11/img/image-#{$i}.jpg");
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
