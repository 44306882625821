function getLocaleByDomain () {
  const host = location.hostname.replace(/(.+\.)?(.*?)\..+/g, '$2')
  const domains = {
    'zekimurenhatti': 'tr',
    'zekimurenhotline': 'en',
    'localhost': 'en'
  }

  return domains[host]
}

export default getLocaleByDomain
