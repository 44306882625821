<template lang="pug">
  Frame.PoetSunOfArt(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 4
      img.image(class="image-"+n, src="/messages/msg_19/img/ataturk-"+n+".jpg")
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'PoetSunOfArt',
  mixins: [
    mixinScene({
      'image': i => ({
        from: 'right: 0%; transform: scale(1) translateX(45%);',
        to: 'right: 50%; transform: scale(0.55) translateX(50%);',
        options: {
          easing: 'ease-out',
          duration: 20
        }
      }),
      'image-2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 20,
          delay: 10
        }
      }),
      'image-3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 8,
          delay: 32
        }
      }),
      'image-4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 8,
          delay: 45
        }
      }),
      'image-idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 57.3632
        }
      })
    }, {
      selector: selector => `.PoetSunOfArt .${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .PoetSunOfArt{
    overflow: hidden;
    z-index: 0;

    .image{
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: 100%;
      z-index: 0;
      will-change: transform;
      transform-origin: 100% 50%;
      transition: all 1s ease;
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
