<template lang="pug">
  #app.App
    transition(name="fadeInFast", mode="out-in")
      Header(ref="Header", v-if="showMenu", :messages="messages")
    About(ref="About")
    router-view(v-bind="{ messages }", v-model="showMenu")
</template>

<script>
import Header from '@/views/partials/Header'
import messages from '@/messages.json'
import About from '@/components/About'

export default {
  name: 'App',
  data () {
    return {
      showMenu: false,
      messages
    }
  },
  components: {
    Header,
    About
  }
}
</script>

<style lang="scss">
  @import "./assets/stylesheet/app";
</style>
