import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    locale: i18n.locale
  },
  actions: {
    setLocale ({ commit }, locale) {
      commit('SET_LOCALE', locale)
    }
  },
  mutations: {
    SET_LOCALE (state, locale) {
      state.locale = locale || process.env.VUE_APP_I18N_FALLBACK_LOCALE
      i18n.locale = state.locale
      window.localStorage.setItem('lang', locale)
      const htmlEl = document.getElementsByTagName('html')[0]
      if (htmlEl) htmlEl.setAttribute('lang', state.locale)
    }
  }
}
