<template lang="pug">
  Frame.Funeral(:class="{ playing, paused: !playing && (started || finished) }")
    .image.solid
    .image.star.star-1
    .image.star.star-2
    .image.star.star-3
    .image.star.star-4
    .image.zeki(data-effect="fadeOut")
    .image.star.star-5
    .image.gradient
    .image.galaxy
    .image.galaxy-black-white(data-effect="fadeOut")
    .image.idle
</template>

<script>
import mixinScene from '@/mixins/scene'

const duration = 84.104

export default {
  name: 'Funeral',
  mixins: [
    mixinScene({
      '.solid': i => ({
        from: 'opacity: 0',
        to: 'opacity: 1',
        options: {
          easeing: 'linear',
          delay: 77,
          duration: 6
        }
      }),
      '.star': i => ({
        from: 'opacity: 0.7',
        to: 'opacity: 1',
        options: {
          easeing: 'ease',
          iterationCount: 'infinite',
          direction: 'alternate',
          duration: 2
        }
      }),
      '.star-1': i => ({
        from: {
          'transform-origin': '31.733333333333334% 31.484257871064468%',
          transform: 'rotate(0deg) scale(2) translate(-17%, -23%)'
        },
        to: {
          transform: 'rotate(45deg) scale(1) translate(0, 0)'
        },
        options: {
          easeing: 'ease',
          fillMode: 'forwards',
          duration
        }
      }),
      '.star-2': i => ({
        from: {
          'transform-origin': '37.06666666666666% 55.02248875562219%',
          transform: 'rotate(0deg) scale(2) translate(-24%, 25%)'
        },
        to: {
          transform: 'rotate(45deg) scale(1) translate(0, 0)'
        },
        options: {
          easeing: 'ease',
          fillMode: 'forwards',
          duration
        }
      }),
      '.star-3': i => ({
        from: {
          'transform-origin': '67.2% 46.326836581709145%',
          transform: 'rotate(0deg) scale(2) translate(21%, 0%)'
        },
        to: {
          transform: 'rotate(45deg) scale(1) translate(0, 0)'
        },
        options: {
          easeing: 'ease',
          fillMode: 'forwards',
          duration
        }
      }),
      '.star-4': i => ({
        from: {
          'transform-origin': '57.599999999999994% 65.96701649175412%',
          transform: 'rotate(0deg) scale(2) translate(12%, 22%)'
        },
        to: {
          transform: 'rotate(45deg) scale(1) translate(0, 0)'
        },
        options: {
          easeing: 'ease',
          fillMode: 'forwards',
          duration
        }
      }),
      '.star-5': i => ({
        from: {
          'transform-origin': '61.86666666666667% 31.334332833583208%',
          transform: 'rotate(0deg) scale(2) translate(11%, -18%)'
        },
        to: {
          transform: 'rotate(45deg) scale(1) translate(0, 0)'
        },
        options: {
          easeing: 'ease',
          fillMode: 'forwards',
          duration
        }
      }),
      '.zeki': i => ({
        from: 'transform: scale(1.65) translateX(10%)',
        to: 'transform: scale(1) translateX(10%)',
        options: {
          easeing: 'ease',
          fillMode: 'forwards',
          duration
        }
      }),
      '.zeki[data-effect="fadeOut"]': i => ({
        from: 'opacity: 1',
        to: 'opacity: 0',
        options: {
          easeing: 'linear',
          delay: 75,
          duration: 4
        }
      }),
      '.gradient': i => ({
        from: 'transform: scale(1)',
        to: 'transform: scale(1.75)',
        options: {
          easeing: 'linear',
          fillMode: 'forwards',
          duration: 84
        }
      }),
      '.galaxy-black-white, .galaxy': i => ({
        from: 'transform: scale(1)',
        to: 'transform: scale(1.75)',
        options: {
          easeing: 'linear',
          fillMode: 'forwards',
          duration: 84
        }
      }),
      '.galaxy-black-white[data-effect="fadeOut"]': i => ({
        from: 'opacity: 1',
        to: 'opacity: 0',
        options: {
          easeing: 'linear',
          duration: 10
        }
      }),
      '.idle': i => ({
        from: 'opacity: 1',
        to: 'opacity: 0',
        options: {
          easeing: 'linear',
          duration
        }
      })
    }, {
      selector: (selector) => `.Funeral ${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .Funeral{
    transition: opacity 1s linear;

    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 1s ease;

      &.solid{
        background-color: black;
        z-index: 6;
        opacity: 0;
      }

      &.star{
        background-image: url('/messages/msg_28/img/star-1.png');

        &-1{
          z-index: 5;
          background-size: 30%;
          background-position: 24% 27%;
        }

        &-2{
          background-size: 20%;
          background-position: 34% 56%;
          z-index: 5;
        }

        &-3{
          background-size: 18%;
          background-position: 71% 46%;
          z-index: 5;
        }

        &-4{
          background-size: 30%;
          background-position: 61% 70%;
          z-index: 5;
        }

        &-5{
          background-size: 16%;
          background-position: 64% 29%;
          z-index: 5;
        }
      }

      &.zeki{
        z-index: 4;
        /*top:-25%;*/
        overflow: visible;
        transform: scale(1.6) translateX(10%);
        background-size: 100% auto;
        background-position: center 100%;
        background-image: url('/messages/msg_28/img/zeki.png');
      }

      &.gradient{
        z-index: 2;
        background-size: auto 100%;
        background-image: url('/messages/msg_28/img/black-gradient.png');
      }

      &.galaxy-black-white{
        z-index: 1;
        background-image: url('/messages/msg_28/img/galaxy-black-white.jpg');
      }

      &.galaxy{
        background-image: url('/messages/msg_28/img/galaxy.jpg');
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
