import Vue from 'vue'
import '@/components'
import 'swiper/swiper-bundle.css'

import App from './App.vue'
import DesktopMessage from './DesktopMessage.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import wait from './wait'
import preloadImages from '@/utils/preloadImages'

Vue.config.productionTip = false

// First load primary images
const orderedImages = [
  '/img/sprite-v2.png'
]

preloadImages(orderedImages)

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

new Vue({
  router,
  store,
  i18n,
  wait,
  render: h => h(
    isMobile ? App : DesktopMessage
  )
}).$mount('#app')
