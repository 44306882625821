<template lang="pug">
  Frame.Highschoolers(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 5
      .image(class="image-"+n, data-fadein)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'Highschoolers',
  mixins: [
    mixinScene({
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 4
        }
      }),
      '3': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 7
        }
      }),
      '4': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 10
        }
      }),
      '5': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 13
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          duration: 0.01,
          delay: 17.216
        }
      })
    }, {
      selector: selector => `.Highschoolers .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .Highschoolers{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      @for $i from 1 through 5 {
        &-#{$i} {
          background-image: url("/messages/msg_22/img/image-#{$i}.jpg");
        }
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.05s linear;
      }
    }
  }
</style>
