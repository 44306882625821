<template lang="pug">
  Frame.AreYouThereBrotherZeki(:class="{ playing, paused: !playing && (started || finished) }")
    .image.image-fill
    .image.image-bg
    .image.image-middle
    .image.image-fg
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'AreYouThereBrotherZeki',
  mixins: [
    mixinScene({
      'fg': i => ({
        from: 'transform: scale(2);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 33
        }
      }),
      'middle': i => ({
        from: 'transform: scale(1.5);',
        to: 'transform: scale(1);',
        options: {
          easing: 'ease',
          duration: 33
        }
      }),
      'fill': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 0.4;',
        options: {
          easing: 'ease',
          duration: 23,
          delay: 10
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 33.128
        }
      })
    }, {
      selector: (selector) => `.AreYouThereBrotherZeki .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .AreYouThereBrotherZeki{
    overflow: hidden;

    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image:
        radial-gradient(rgba(25,25,25,0),
      rgba(25,25,25,.75));
    }

    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: all 1s ease;
      will-change: transform;

      &-bg{
        background-image: url('/messages/msg_1/img/img-background.jpg');
        z-index: 0;
      }

      &-middle{
        background-image: url('/messages/msg_1/img/img-middleground.png');
        transform: scale(1.5);
        left: 5%;
        z-index: 3;
      }

      &-fill {
        width:100%;
        height:100%;
        z-index: 4;
        opacity: 0;
        background-color: black;
        will-change: opacity;
      }

      &-fg{
        background-image: url('/messages/msg_1/img/img-foreground.png');
        transform: scale(2);
        z-index: 5;
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
