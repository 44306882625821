<template lang="pug">
  Frame.LongingForZekiMuren(:class="{ playing, paused: !playing && (started || finished) }")
    - var n=1;
    while n <= 3
      .image(class="image-"+n, data-opacity)
      - n++;
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'LongingForZekiMuren',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'transform: scale(1);',
        to: 'transform: scale(1.35);',
        options: {
          easing: 'linear',
          duration: 24
        }
      }),
      '2': i => ({
        from: 'transform: scale(1);',
        to: 'transform: scale(1.35);',
        options: {
          easing: 'linear',
          duration: 24
        }
      }),
      '2[data-opacity]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 10,
          delay: 6
        }
      }),
      '3[data-opacity]': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 1,
          delay: 22
        }
      }),
      '3': i => ({
        from: 'background-position: 0 50%;',
        to: 'background-position: 100% 50%;',
        options: {
          easing: 'ease',
          duration: 13,
          delay: 22
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 33.2
        }
      })
    }, {
      selector: (selector) => `.LongingForZekiMuren .image-${selector}`
    })
  ]
}
</script>

<style lang="scss" scoped>
  .LongingForZekiMuren{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;

      &-1{
        background-image: url('/messages/msg_5/img/5_Longing_for_ZM_Still_11.jpg');
      }
      &-2{
        background-image: url('/messages/msg_5/img/5_Longing_for_ZM_Still_silo.jpg');
        opacity:0;
      }
      &-3{
        z-index: 2;
        background-image: url('/messages/msg_5/img/5_Longing_for_ZM_Still_2.jpg');
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
