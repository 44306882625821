<template lang="pug">
  Frame.ZekiInMovie(:class="{ playing, paused: !playing && (started || finished) }")
    - var n = 1;
    while n <= 2
      .image(class="image-" + n)
      - n++
    .image.image-idle
</template>

<script>
import mixinScene from '@/mixins/scene'

export default {
  name: 'ZekiInMovie',
  mixins: [
    mixinScene({
      '1': i => ({
        from: 'opacity: 1;',
        to: 'opacity: 0;',
        options: {
          easing: 'linear',
          duration: 1
        }
      }),
      '2': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 0.1
        }
      }),
      'idle': i => ({
        from: 'opacity: 0;',
        to: 'opacity: 1;',
        options: {
          easing: 'linear',
          duration: 28.9952
        }
      })
    }, {
      selector: (selector) => `.ZekiInMovie .image-${selector}`
    })
  ]
}
</script>

<style lang="scss">
  .ZekiInMovie{
    .image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      transition: all 1s linear;
      background-position: center;

      &-1{
        z-index: 1;
        background-position: center 10%;
        background-image: url('/messages/msg_12/img/12_ZekiInMovies_ImageBefore.jpg');
      }

      &-2{
        background-image: url('/messages/msg_12/img/12_ZekiInMovies_v3.gif');
      }
    }

    &.playing, &.paused{
      .image{
        transition: all 0.1s linear;
      }
    }
  }
</style>
