<template lang="pug">
  .Pulse
    span.Pulse-ring(v-for="i in 3")
</template>

<script>
export default {
  name: 'Pulse'
}
</script>

<style lang="scss" scoped>
  .Pulse{
    position: relative;
    width: 120px;
    height: 120px;

    &-ring{
      position: absolute;
      left: -2px;
      top: -2px;
      z-index: 999;
      width: 100%;
      height: 100%;
      border: 2px solid $primary;
      border-radius: 50%;
      opacity: 0;
      animation: pulse 3s ease-out;
      animation-iteration-count: infinite;

      &:nth-child(2){animation-delay: 1s;}
      &:last-child{animation-delay: 2s;}
    }

  }
</style>
