<template lang="pug">
  keep-alive
    component(:is='`Icon${name}`', :options='$attrs')
</template>

<script>
import '@/assets/icons'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
